$primary: #8C5CB3;
$--bs-primary: #5b73e8;

:root {
    --bs-primary: #5b73e8;
    --bs-primary-rgb: 91, 115, 232;
    --bs-btn-color: #fff;
    --bs-btn-bg: #1a214d;
    --bs-btn-border-color: #1a214d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #f8b91d;
    --bs-btn-hover-border-color: #f8b91d;
    --bs-btn-focus-shadow-rgb: 116, 136, 235;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #495cba;
    --bs-btn-active-border-color: #4456ae;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5b73e8;
    --bs-btn-disabled-border-color: #5b73e8;
    --rs-sidenav-default-bg: #fff;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1a214d;
    --bs-btn-border-color: #1a214d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #f8b91d;
    --bs-btn-hover-border-color: #f8b91d;
    --bs-btn-focus-shadow-rgb: 116, 136, 235;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #495cba;
    --bs-btn-active-border-color: #4456ae;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5b73e8;
    --bs-btn-disabled-border-color: #5b73e8;
}
