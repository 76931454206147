.loginpage {
    background-color: #f6f9ff;
    margin: 0;
    max-width: unset;
}

.login-logo {
    height: 100px;
}

.form-translate {
    transform: translate(0, -50px);
}