@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&display=swap');

* {
    box-sizing: border-box;
    font-family: sans-serif;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

#root {
    overflow-x: clip;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
}

main {
    max-width: 1140px;
    margin: auto;
}

.primary-color {
    color: $primary;
}

.page-boundaries {
    padding: 0 100px;
}

.download-btn {
    background-color: #615CB3;
    display: inline-flex;
    gap: 8px;
}

.d-contents {
    display: contents !important;
}

.icon-button {
    display: flex;
    gap: 12px;
    align-items: center;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
}

/* For the scrollbar thumb (the draggable handle) */
::-webkit-scrollbar-thumb {
    background-color: #b5b5b5;
    /* Color of the thumb */
    border-radius: 4px;
    /* Rounded corners */
}

/* On hover, style the thumb */
::-webkit-scrollbar-thumb:hover {
    background-color: #717171;
    /* Color of the thumb on hover */
}

/* For the scrollbar track when it is in a "scrollable" state */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Color of the track */
}

